














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// TODO(Andrew): remove
// @ts-nocheck
import Vue from "vue"
import invariant from "invariant"
import Sortable from "sortablejs"
import Audio from "@/components/GroupTeams/Common/Audio"
import MissionAddModal from "@/components/Game/MissionAddModal.vue"
import { mapState, mapGetters } from "vuex"
import _, { cloneDeep } from "lodash"
import { v4 } from "uuid"
import VueDraggable from "vuedraggable"
import { ExportToCsv } from "export-to-csv"
import { fetchGames } from "@/services/game.service"
import RunStatus from "@shared/enums/Game"
import MissionType from "@shared/enums/Mission"
import PlayType from "@shared/enums/PlayType"
import MissionCollection from "@shared/MissionCollection"
import Collection from "@shared/Collection"
import rules from "@/config/rules"
import InlineHelpText from "@/constants/inline-help-text"
import InputButton from "@/components/ui/InputButton/InputButton.vue"

import truncate from "@/helpers/truncate"
import { fetchCustomerMissions } from "@/services/api.service"

import {
  RtbRow,
  RtbCol,
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbTextarea,
  RtbSelect,
  RtbInlineEdit,
  RtbCheckbox,
  RtbTabs,
  RtbTab,
  RtbTable,
  RtbSpinner,
  RtbInlineHelp,
  RtbInputButton
} from "@/components/ui"

import ImageUploaderNext from "@/components/ImageUploader.vue"
import HostlessVideoSelector from "@/components/Hostless/VideoSelector/VideoSelector.vue"
import { reorderMissions } from "@/entities/game/util"
import Uploader from "@/helpers/uploader"
import { copyToClipboard } from "@/helpers"
import { db } from "@/firebase"
import activityState, {
  open as openActivtyPanel,
  close as closeActivityPanel
} from "@/components/drawers/activity"

const TRUNCATE_LIMIT = 256
const RESTRICTED_GAME_TYPES = [RunStatus.MASTER, RunStatus.BLOCK]

const getUpdatedMissionsPsition = (missions, prevIndex, newIndex) =>
  reorderMissions(missions, prevIndex, newIndex).reduce((acc, { id, pos }) => {
    acc[`${id}/pos`] = pos
    return acc
  }, {})

import AudioOption from "@shared/enums/AudioOverride"

const AUDIO_OPTIONS = [
  {
    label: "Auto",
    value: AudioOption.AUTO
  },
  {
    label: "All Talk",
    value: AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER
  },
  {
    label: "Host Only",
    value: AudioOption.EVERYONE_CAN_HEAR_ONLY_HOST
  },
  {
    label: "Team Talk",
    value: AudioOption.EVERYONE_CAN_HEAR_TEAMMATES
  }
]

import BreakoutType from "@shared/enums/Breakout"

const breakoutOptions = [
  { value: BreakoutType.UNDO, label: "Undo" },
  { value: BreakoutType.BY_SIZE, label: "Breakout By Users Per Team" },
  { value: BreakoutType.BY_POLL, label: "Breakout By Poll Response" },
  { value: BreakoutType.BY_LAST_POLL, label: "Breakout By Last Poll Response" },
  {
    value: BreakoutType.BY_NUMBER_OF_TEAMS,
    label: "Breakout By Number Of Teams"
  }
]

import Session from "@shared/Session"
import useMissions from "@/use/useMissions"

export default Vue.extend({
  name: "Edit",
  components: {
    RtbInputButton,
    InputButton,
    RtbRow,
    RtbCol,
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbTextarea,
    RtbSelect,
    RtbInlineEdit,
    RtbCheckbox,
    RtbTabs,
    RtbTab,
    RtbTable,
    RtbSpinner,
    RtbInlineHelp,
    ImageUploaderNext,
    Audio,
    MissionAddModal,
    VueDraggable,
    FileUploader: () => import("@/components/FileUploader"),
    GameSettings: () => import("@/components/Game/GameSettings.vue"),
    HostlessVideoSelector
  },
  setup() {
    const { missions } = useMissions()
    return { missions }
  },
  data() {
    return {
      BreakoutType,
      behaviors: Object.values(MissionType),
      activityState,
      AUDIO_OPTIONS,
      editGameDialog: false,
      passedGame: null,
      hints: [],
      moveMissionsPos: null,
      selected: [],
      tab: 0,
      importingData: false,
      representImages: [],
      flagged: false,
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      tries: ["Unlimited", "1", "2", "3", "4", "5"],
      trwRandomOverride: [
        { label: "None", id: null },
        { label: "Random", id: true },
        { label: "Not Random", id: false }
      ],
      trwOptionsOverride: [{ label: "None", id: null }, 1, 2, 3, 4, 5, 6],
      numOfBreakoutPlayersArr: ["None", 1, 2, 3, 4, 5, 6],
      breakoutOptions,
      mission: {
        name: "",
        title: "",
        instruction: "",
        behavior: "",
        answer: ""
      },
      oldGameID: this.$store.getters.gameID,
      newGameID: this.$store.getters.gameID,
      newOrgID: this.$store.getters.orgID,
      oldOrgID: this.$store.getters.orgID,
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      copying: false,
      adding: false,
      tmp: "",
      search: "",
      pagination: {},
      behavior: "",
      typicalModes: this.$store.getters.typicalModes,
      playTypes: this.$store.getters.playTypes,
      playType: PlayType.TEAM_SPEED_MATTERS,
      escapeRooms: [],
      headers: [
        {
          sortable: false
        },
        {
          text: "#",
          value: "pos",
          align: "left",
          sortable: true
        },
        {
          text: "Mission",
          value: "avatar",
          align: "left",
          sortable: false
        },
        {
          text: "Points",
          value: "points",
          align: "left",
          sortable: true
        },
        {
          text: "Instructions",
          value: "Instructions",
          align: "left",
          sortable: false
        },
        {
          text: "Answer",
          value: "Answer",
          align: "left",
          sortable: false
        },
        {
          text: "Elements",
          value: "Elements",
          align: "left",
          sortable: false
        },
        {
          text: "Play Type",
          value: "playType",
          align: "left",
          sortable: false
        },
        {
          text: "Tries",
          value: "tries",
          align: "left",
          sortable: false
        },
        {
          text: "Modes",
          value: "modeLetters",
          align: "left",
          sortable: false
        },
        {
          text: "Timer",
          value: "time",
          align: "left",
          sortable: true
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false
        }
      ],
      isMisssionAddModalVisible: false,
      games: [],
      loading: false,
      slidesLoading: false,
      slidesItemsLeft: 0,
      urlCopyText: null,
      localGame: null,
      exporting: false
    }
  },
  computed: {
    ...mapState("group", ["modes"]),
    ...mapGetters("auth", ["token", "clientID"]),
    ...mapGetters(["game", "user", "plays", "gameID", "urlID"]),
    slides: {
      get() {
        return (this.mission?.representImages || []).map((src, index) => ({
          src,
          duration: this.mission?.durations?.[index]
        }))
      },
      set(value) {
        let images = []
        let durations = []
        value.forEach(({ src, duration }) => {
          images.push(src)
          durations.push(duration || 0)
        })

        this.$set(this.mission, "durations", durations)
        this.$set(this.mission, "representImages", images)
      }
    },
    audioClue: {
      get() {
        if (typeof this.mission?.audio === "string") {
          const soruce = this.mission?.audio
          return this.gameAudioTracks.find(track => track.source === soruce)?.id
        }

        return this.mission?.audio?.id
      },
      set(value) {
        const audio = this.$store.getters.gameAudio || {}
        this.mission.audio = audio[value] || null
      }
    },
    audioOption: {
      get() {
        return this.mission?.audioOption || AudioOption.AUTO
      },
      set(value) {
        this.mission = { ...this.mission, audioOption: value }
      }
    },
    missionBackgroundOpacity: {
      get() {
        const int = parseInt(this.mission?.backgroundOpacity)
        return isNaN(int) ? 30 : int
      },
      set(value) {
        this.mission = { ...this.mission, backgroundOpacity: value }
      }
    },
    breakoutOption: {
      get() {
        const value = this.mission?.breakout || {}

        if (value.returnTeamToOriginalAssignment) return BreakoutType.UNDO

        if (value.size) return BreakoutType.BY_SIZE

        if (value.breakoutMissionID) return BreakoutType.BY_POLL

        if (value.breakoutLastPoll) return BreakoutType.BY_LAST_POLL

        if (value.nOfTeams > -1) return BreakoutType.BY_NUMBER_OF_TEAMS

        return null
      },
      set(val) {
        let breakout = {}

        if (val === BreakoutType.UNDO) {
          breakout.returnTeamToOriginalAssignment = true
        } else if (val === BreakoutType.BY_SIZE) {
          breakout.size = true
        } else if (val === BreakoutType.BY_POLL) {
          breakout.breakoutMissionID = true
        } else if (val === BreakoutType.BY_LAST_POLL) {
          breakout.breakoutLastPoll = true
        } else if (val === BreakoutType.BY_NUMBER_OF_TEAMS) {
          breakout.nOfTeams = 0
        } else {
          breakout = null
        }

        this.mission = Object.assign({}, this.mission, { breakout })
      }
    },
    breakoutMissionID: {
      get() {
        return this.mission?.breakout?.breakoutMissionID ?? false
      },
      set(val) {
        this.mission = Object.assign({}, this.mission, {
          breakout: {
            breakoutMissionID: val
          }
        })
      }
    },
    nOfBreakoutTeams: {
      get() {
        return this.mission?.breakout?.nOfTeams
      },
      set(value) {
        this.mission = Object.assign({}, this.mission, {
          breakout: {
            nOfTeams: value
          }
        })
      }
    },
    keepTeams: {
      get() {
        return this.mission?.breakout?.keepTeams
      },
      set(value) {
        _.set(this.mission, "breakout.keepTeams", value)
      }
    },
    allowSingle: {
      get() {
        return this.mission?.breakout?.allowSingle
      },
      set(value) {
        _.set(this.mission, "breakout.allowSingle", value)
      }
    },
    breakoutTeamsName: {
      get() {
        return this.mission?.breakout?.teamsName
      },
      set(value) {
        this.$set(this.mission.breakout, "teamsName", value || null)
      }
    },
    toDifferentTeams: {
      get() {
        return this.mission?.breakout?.toDifferentTeams
      },
      set(value) {
        _.set(this.mission, "breakout.toDifferentTeams", value)
      }
    },
    size: {
      get() {
        return this.mission?.breakout?.size
      },
      set(val) {
        this.mission = Object.assign({}, this.mission, {
          breakout: {
            size: val
          }
        })
      }
    },
    isCreatePoll() {
      return !!(
        (this.mission?.behavior === MissionType.FreeForm &&
          this.mission?.voting === true) ||
        this.mission?.createPoll
      )
    },
    missionAudioTrack: {
      get() {
        if (typeof this.mission?.autoMissionAudio === "string") {
          const soruce = this.mission?.autoMissionAudio
          return this.gameAudioTracks.find(track => track.source === soruce)?.id
        }

        return this.mission?.autoMissionAudio?.id
      },
      set(value) {
        const audio = this.$store.getters.gameAudio || {}
        this.mission.autoMissionAudio = audio[value] || null
      }
    },
    missionPositions() {
      return this.allMissions.map(mission => ({
        id: mission.id,
        name: `${mission.pos} ${mission.name}`
      }))
    },
    selectedMissions() {
      return this.selected.length > 0
    },
    isWriteAvailable() {
      return !this.loading && this.isValid && !this.isSourceMissionMissing
    },
    isValid() {
      return this.mission?.behavior && this.mission?.title && this.mission?.name
    },
    isSourceMissionMissing() {
      return this.isFactMatch && this.mission && !this.mission.freeFormMissionID
    },
    allMissions() {
      const array = MissionCollection.normalize(this.missions || {})
      if (this.flagged) return array.filter(mission => mission.flagged)
      return array.map(mission => ({ ...mission, theKey: mission.id }))
    },
    freeFormMissions() {
      return this.allMissions.filter(
        ({ behavior }) =>
          behavior === MissionType.FreeForm ||
          behavior === MissionType.TakePhoto ||
          behavior === MissionType.Photo ||
          behavior === MissionType.PhotoBooth ||
          behavior === MissionType.Represent ||
          behavior === MissionType.Giphy ||
          behavior === MissionType.VideoTeam
      )
    },
    ratingPollMissions() {
      return this.allMissions.reduce(
        (acc, item) => {
          if (
            item.behavior === MissionType.RatingPoll &&
            item.id !== this.mission?.id
          ) {
            acc.push(item)
          }
          return acc
        },
        [{ name: "none", id: null }]
      )
    },
    twoTruthsMissions() {
      return this.allMissions.filter(
        item => item.behavior === MissionType.TwoTruths
      )
    },
    orgs() {
      return Collection.normalize(this.$store.getters.orgs || {})
    },
    gameAudioTracks() {
      return Collection.normalize(this.$store.getters.gameAudio || {})
    },
    gameAudio() {
      const arr = this.gameAudioTracks
      const newArr = []
      newArr.push({ id: 0, label: "Nothing" })
      for (let i = 0; i < arr.length; i++)
        newArr.push({ id: arr[i].source, label: arr[i].name })
      return newArr
    },
    missionType() {
      return this.mission?.behavior
    },
    isLink() {
      return this.missionType === MissionType.Link
    },
    isFactMatch() {
      return this.missionType === MissionType.FactMatch
    },
    isYouTube() {
      return this.missionType === MissionType.YouTube
    },
    isTwoTruthsReveal() {
      return this.missionType === MissionType.TwoTruthsReveal
    },
    isMultipleChoise() {
      return this.missionType === MissionType.MultipleChoice
    },
    isRoyalRumble() {
      return this.missionType === MissionType.RoyalRumble
    },
    isWhiteElephant() {
      return this.missionType === MissionType.WhiteElephant
    },
    isPhotoMission() {
      return [MissionType.Photo, MissionType.TakePhoto].includes(
        this.missionType
      )
    },
    idDefaultAnswer() {
      return [
        MissionType.Unlock,
        MissionType.Text,
        MissionType.PriceIsRight,
        MissionType.Categories,
        MissionType.DrawingPictionary,
        MissionType.Lipdub,
        MissionType.Charades,
        MissionType.FamilyFeud
      ].includes(this.missionType)
    },
    hasMultipleAnswers() {
      return [MissionType.MultipleChoice, MissionType.Poll].includes(
        this.missionType
      )
    },
    isRatingPoll() {
      return MissionType.RatingPoll === this.missionType
    },
    isPhotoBooth() {
      return MissionType.PhotoBooth === this.missionType
    },
    isPriceIsRight() {
      return MissionType.PriceIsRight === this.missionType
    },
    isWhiteElephant() {
      return MissionType.WhiteElephant === this.missionType
    },
    isVideo() {
      return MissionType.Video === this.missionType
    },
    isVideoIndividual() {
      return MissionType.VideoIndividual === this.missionType
    },
    isVideoTeam() {
      return MissionType.VideoTeam === this.missionType
    },
    isMatchGame() {
      return MissionType.MatchGame === this.missionType
    },
    isOrderTheCardsGame() {
      return MissionType.OrderTheCards === this.missionType
    },
    isSlides() {
      return MissionType.Slides === this.missionType
    },
    isKaraokePowerpoint() {
      return this.mission.karaokePowerpoint
    },
    canDelete() {
      return (
        !!this.user.super ||
        !RESTRICTED_GAME_TYPES.includes(this.game?.runStatus)
      )
    },
    pollMissionsData() {
      return this.allMissions
        .filter(mission => mission.behavior === MissionType.Poll)
        .sort((a, b) => a.missionID - b.missionID)
        .map(({ id, name }) => ({ value: id, label: name }))
    },
    isDbShard() {
      return Boolean(Session.getKey(this.clientID))
    },
    selectedTwoMission() {
      return this.selected.length === 2
    },
    rules() {
      return rules
    },
    numberOfLinkedMissions() {
      const length = this.mission?.linkedMissions?.length || 0
      return length !== 2 ? length + 1 : length
    },
    hasRepresentImages() {
      return (
        this.mission.representImages && this.mission.representImages.length > 0
      )
    }
  },
  watch: {
    "mission.behavior"(value) {
      if (value === MissionType.WordCloud) {
        this.mission.numOfTries = this.tries[0]
      }
    },
    "mission.karaokePowerpoint"(value) {
      if (value) {
        this.$set(this.mission, "documentUrl", null)
      }
    },
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    selectedTwoMission(val) {
      if (!val) return

      const [first, second] = this.selected
      const middle = []
      const missions = this.allMissions

      for (let i = first.pos + 1; i < second.pos; i++) middle.push(missions[i])

      if (middle.length) this.selected = [first, ...middle, second]
    },
    deleting(to) {
      this.cancel(to)
      if (to) {
        this.$nextTick(() => {
          this.$refs.removeBtn.focus()
        })
      }
    },
    game: {
      handler(value) {
        this.localGame = cloneDeep(value)
      },
      immediate: true
    }
  },
  async created() {
    this.InlineHelpText = InlineHelpText
    await this.$store.dispatch("subscribeToGameAudio")
    this.games = await this.$services
      .get("game")
      .then(service =>
        service.getGamesByRunStatus(this.$store.getters.orgID, [
          RunStatus.BLOCK,
          RunStatus.USER_TEMPLATE
        ])
      )

    const value = await fetchGames({
      orgID: this.$store.getters.orgID,
      clientID: this.clientID
    })

    this.escapeRooms = Collection.normalize(value || {})
      .filter(game => game.escapeRoom)
      .map(game => ({
        id: game.id,
        label: game.externalName || game.name || "undefined"
      }))
  },
  mounted() {
    /* eslint-disable no-new */
    this.$nextTick(() => {
      const el = this.$refs.sortableTable
      if (el) {
        el.defaultPagination.rowsPerPage = 300
        new Sortable(el.$el.getElementsByTagName("tbody")[0], {
          draggable: ".sortableRow",
          handle: ".sortHandle",
          onEnd: this.dragReorder
        })
      }
    })
  },
  methods: {
    openActivtyPanel,
    closeActivityPanel,
    hasHostlessVideo(mission) {
      return Boolean(mission?.["hostless-video"])
    },
    async exportCSV() {
      const fieldsMap = {
        id: "Mission ID",
        name: "NAme",
        lastname: "Title",
        instructions: "Insstructions",
        points: "Points",
        behavior: "Type",
        answer: "Answer",
        answer1: "Answer1",
        answer2: "Answer2",
        answer3: "Answer3",
        answer4: "Answer4",
        answer5: "Answer5",
        playType: "Play Type",
        duration: "Duration",
        numOfTries: "NumOfTries",
        photo: "Photo",
        youtube: "YouTube"
      }
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        // title: "Attendences",
        filename: this.game.name,
        useTextFile: false,
        useBom: true,
        headers: Object.values(fieldsMap)
      }
      const csvExporter = new ExportToCsv(options)
      const keys = Object.keys(fieldsMap)
      console.log("Missions", this.allMissions)
      const data = this.allMissions.map(item => {
        const row = {}
        keys.forEach(key => (row[key] = item[key] ? item[key] : ""))
        return row
      })
      console.log("SCV", data)
      csvExporter.generateCsv(data)
    },
    async moveMissions() {
      const { orgID, gameID } = this.$store.getters
      const selected = this.selected
      const targetID = this.moveMissionsPos
      const missions = this.allMissions.filter(mission =>
        selected.every(m => m.id !== mission.id)
      )
      const index = missions.findIndex(mission => mission?.id === targetID) + 1
      const ref = db.auxiliary().ref(`org/${orgID}/game/${gameID}/missions`)

      const update = [
        ...missions.slice(0, index),
        ...selected,
        ...missions.slice(index)
      ]
        .map((mission, i) => ({ ...mission, pos: i }))
        .reduce((acc, val) => {
          acc[`${val.id}/pos`] = val.pos || 0
          return acc
        }, {})

      await ref.update(update)
      this.selected = []
    },
    async deleteMissions() {
      await Promise.all(
        this.selected.map(mission =>
          this.$store.dispatch("removeMission", mission)
        )
      )

      this.justReorder()
    },
    fileName(file: File) {
      return `documents/${v4()}.pdf`
    },
    uploadFiles(e: InputEvent) {
      const { files } = e.target as HTMLInputElement
      if (files !== null) {
        const uploader = new Uploader(this.$store.state.auth.token)
        const array = Array.from(files)
        this.slidesLoading = true
        this.slidesItemsLeft = array.length
        array.forEach(file => {
          uploader
            .upload(file, { fileName: `${v4()}-${file.name}` })
            .then(res => {
              this.slidesItemsLeft--
              this.addNewRepresentImage(res)
              if (this.slidesItemsLeft === 0) {
                this.slidesLoading = false
              }
            })
        })
      }
    },
    removeWhiteElephantImage(index: number) {
      invariant(
        Array.isArray(this.mission?.whiteElephantImages),
        "Current mission has no images"
      )
      this.mission.whiteElephantImages.splice(index, 1)
    },
    removeRepresentImage(index: number) {
      invariant(
        Array.isArray(this.mission?.representImages),
        "Current mission has no represent images"
      )
      this.mission.representImages.splice(index, 1)

      if (this.mission.durations?.length) {
        this.mission.durations.splice(index, 1)
      }
    },
    removeRepresentPairImage(index: number) {
      invariant(
        Array.isArray(this.mission?.representImagesPair),
        "Current mission has no represent images"
      )
      this.mission.representImagesPair.splice(index, 1)
    },
    maybeDeleteRepresentImages() {
      if (confirm("Are you sure you want to delete all the images?") === true) {
        this.mission.representImages = []

        if (this.mission.durations) {
          this.mission.durations = []
        }
      }
    },
    updateOrgID(orgID: string) {
      this.newOrgID = orgID
      this.$store.dispatch("setOrgID", orgID)
    },
    modeLetters(mission) {
      return this.modes.reduce((acc, mode) => {
        if (mission[mode]) {
          acc = acc + mode[0].toUpperCase()
        }
        return acc
      }, "")
    },
    setDefaultModes(mission) {
      const typicalModes = this.typicalModes[mission.behavior]
      const modes = Object.values(this.modes)

      const copy = cloneDeep(mission)

      for (const i in modes) {
        copy[modes[i]] = false
      }

      for (const j in typicalModes) {
        copy[typicalModes[j].toLowerCase()] = true
      }

      this.mission = copy
    },
    missionHasNoModes(mission) {
      return this.modes.reduce((result, modeName) => {
        if (mission[modeName]) {
          result = false
        }
        return result
      }, true)
    },
    allModes(mission) {
      var arr = Object.values(this.modes)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.name = arr[i]
        obj.value = mission[arr[i]]
        newArr.push(obj)
      }
      return newArr
    },
    behaviorSafe(m) {
      if (m?.length > 3) {
        return "list-" + m.replace(/\s+/g, "-").toLowerCase().replace(":", "")
      } else if (!m) {
        return "list-invalid"
      } else {
        return null
      }
    },
    async pushToGame() {
      const room = this.game.started ? "game" : "pickteams"
      await this.$router.push(`/${room}/${this.urlID}`)
    },
    async pushToGameUsers() {
      await this.$router.push(`/game/${this.urlID}/users`)
    },
    justReorder() {
      const update = getUpdatedMissionsPsition(this.allMissions, 0, 0)

      const { orgID, gameID } = this.$store.getters
      return this.$services
        .get("game")
        .then(service => service.updateMissionPositions(orgID, gameID, update))
    },
    dragReorder({ oldIndex, newIndex }) {
      const update = getUpdatedMissionsPsition(
        this.allMissions,
        oldIndex,
        newIndex
      )
      const { orgID, gameID } = this.$store.getters
      return this.$services
        .get("game")
        .then(service => service.updateMissionPositions(orgID, gameID, update))
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    resetForm() {
      this.mission = {
        name: "",
        avatar: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(mission) {
      this.viewing = true
      this.mission = mission
    },
    closeModalDetail() {
      this.viewing = false
      this.mission = null
    },
    openRemoveDialog(mission) {
      this.mission = mission
      this.deleting = true
    },
    async remove() {
      await this.$store.dispatch("removeMission", this.mission)
      this.justReorder()
      this.deleting = false
    },
    edit(mission) {
      this.mission = cloneDeep(mission)

      if (this.missionHasNoModes(mission)) {
        this.setDefaultModes(mission)
      }

      this.hints = this.mission.hints || []

      this.copying = false
      this.editing = true
    },
    copy(mission) {
      this.copying = true
      this.editing = true
      this.mission = mission
    },
    add() {
      this.editing = true
      this.adding = true
      this.mission = {
        name: "",
        title: "",
        instructions: "",
        behavior: "",
        answer: "",
        gameID: this.gameID,
        video: "",
        photo: "",
        time: 0,
        pos: 0,
        points: 0,
        multiCorrect: 1
      }
    },
    async update() {
      this.loading = true
      this.mission.gameID = this.newGameID
      this.mission.orgID = this.newOrgID
      if (Array.isArray(this.hints))
        this.mission.hints = this.hints.filter(hint => hint)
      const original = this.allMissions.find(
        mission => mission.id === this.mission.id
      )
      if (original !== undefined && original.pos !== this.mission.pos) {
        const update = getUpdatedMissionsPsition(
          this.allMissions,
          original.pos,
          this.mission.pos
        )

        const { orgID, gameID } = this.$store.getters
        await this.$services
          .get("game")
          .then(service =>
            service.updateMissionPositions(orgID, gameID, update)
          )
      }
      if (this.adding || this.copying) {
        if (!this.copying) {
          this.mission.pos = this.allMissions.length + 1
        }
        console.log("ADD OR COPYING", this.newGameID.id)
        await this.$store.dispatch("addToMissions", {
          array: [this.mission],
          gameID: this.newGameID,
          orgID: this.newOrgID
        })
      } else {
        await this.$store.dispatch("updateMission", this.mission)
      }
      // update game values
      this.$store.dispatch("Games/updateGameAny", {
        theKey: this.game?.theKey,
        hostlessDefaultVideo: this.localGame?.hostlessDefaultVideo ?? null,
        hostlessVideoName: this.localGame?.hostlessVideoName ?? null
      })

      this.editing = false
      this.adding = false
      this.$store.dispatch("setOrgID", this.oldOrgID)

      this.loading = false
    },
    async onCreateMission({ afterID, mission: _mission, linkMissionID }) {
      const { orgID, gameID } = this.$store.getters
      const missions = this.allMissions
      const ref = db.auxiliary().ref(`org/${orgID}/game/${gameID}/missions`)
      const missionID = ref.push().key
      const mission = { ..._mission, id: missionID, theKey: missionID }
      const index = missions.findIndex(mission => mission?.id === afterID) + 1

      if (linkMissionID) {
        if (mission.behavior === MissionType.FactMatch) {
          mission.freeFormMissionID = linkMissionID
        } else if (mission.behavior === MissionType.TwoTruthsReveal) {
          mission.twoTruthsMissionID = linkMissionID
        }
      }

      const update = [
        ...missions.slice(0, index),
        mission,
        ...missions.slice(index)
      ]
        .map((mission, i) => ({ ...mission, pos: i }))
        .reduce((acc, val) => {
          if (val.id === missionID) {
            acc[missionID] = val
          } else {
            acc[`${val.id}/pos`] = val.pos || 0
          }
          return acc
        }, {})

      await ref.update(update)
    },
    addWhiteElephantImage({ image = "", label = "" }) {
      if (Array.isArray(this.mission?.whiteElephantImages)) {
        this.mission.whiteElephantImages.push({ image, label })
      } else {
        this.$set(this.mission, "whiteElephantImages", [{ image, label }])
      }
    },
    addNewRepresentImage(image = "") {
      if (Array.isArray(this.mission?.representImages)) {
        this.mission.representImages.push(image)
      } else {
        this.$set(this.mission, "representImages", [image])
      }

      if (!Array.isArray(this.mission.durations) && this.isSlides) {
        this.$set(this.mission, "durations", [])
      }
    },
    addNewRepresentImagePair() {
      if (Array.isArray(this.mission?.representImagesPair)) {
        this.mission.representImagesPair.push("")
      } else {
        this.$set(this.mission, "representImagesPair", [""])
      }
    },
    async onSaveCustomerMissions() {
      this.importingData = true
      try {
        await fetchCustomerMissions({
          clientID: this.game.clientID,
          gameID: this.gameID
        })
      } finally {
        this.importingData = false
      }
    },
    addNewPhotoBoothImage() {
      if (Array.isArray(this.mission?.photoboothImages)) {
        this.mission.photoboothImages.push("")
      } else {
        this.$set(this.mission, "photoboothImages", [""])
      }
    },
    removePhotoBoothImage(index: number) {
      invariant(
        Array.isArray(this.mission?.photoboothImages),
        "Current mission has no photoboth images"
      )
      console.log(index)
      this.mission.photoboothImages.splice(index, 1)
    },
    addMissionImage(key) {
      if (!key) throw new Error("Key is mandatory")
      if (Array.isArray(this.mission?.[key])) {
        this.mission[key].push("")
      } else {
        this.$set(this.mission, key, [""])
      }
    },
    removeMissionImage(index, key) {
      if (!key) throw new Error("Key is mandatory")
      invariant(
        Array.isArray(this.mission?.[key]),
        "Current mission has no photoboth images"
      )
      this.mission[key].splice(index, 1)
    },
    updateMission(mission) {
      mission.gameID = this.newGameID
      mission.orgID = this.newOrgID
      this.$store.dispatch("updateMission", mission)
    },
    shouldTruncate(string: string, limit = TRUNCATE_LIMIT) {
      return string?.length > limit
    },
    truncate(string: string, limit = TRUNCATE_LIMIT) {
      return truncate(string, limit)
    },
    copyAudioUrl(text) {
      copyToClipboard(text)

      this.urlCopyText = "Audio url copied!"
      setTimeout(() => {
        this.urlCopyText = null
      }, 1500)
    },
    onUpdateLocalMission(mission = {}) {
      console.log("UPDATE MISSION")
      this.mission = { ...this.mission, ...mission }
    },
    openEditSettings() {
      this.editGameDialog = true
      this.passedGame = { ...this.game }
    },
    closeEditSettings() {
      this.editGameDialog = false
      this.passedGame = null
    },
    async exportActivity() {
      try {
        this.exporting = true
        const exportService = await this.$services.get("export")
        const contentToolID = await exportService.exportActivity(this.mission)
        if (contentToolID !== this.mission.contentToolID) {
          this.mission.contentToolID = contentToolID
          this.update()
        }
      } finally {
        this.exporting = false
      }
    },
    nominateScribeChange() {
      this.$delete(this.mission, "results")
      this.mission.playType = PlayType.INDIVIDUAL_SPEED_DOES_NOT_MATTER
    },
    unlimitedTeamsChange(value) {
      if (value) {
        this.$set(this.mission, "explain", true)
      } else {
        this.$delete(this.mission, "explain")
      }
    },
    getLikedMissionId(index) {
      return this.mission?.linkedMissions?.[index] || null
    },
    linkedMissionChange(index, id) {
      if (!this.mission?.linkedMissions) {
        this.$set(this.mission, `linkedMissions`, [])
      }

      if (id) {
        this.$set(this.mission.linkedMissions, `${index}`, id)
      } else {
        this.$delete(this.mission.linkedMissions, `${index}`)
      }
    }
  }
})
